@import 'src/utils/utils';

.current {
  @include zero;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: $Main-2;
  margin-bottom: 20px;
  gap: 10px;
  transition: box-shadow 0.3s;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
    gap: 15px;
  }
  @media(min-width: $xxl) {
    @include box;
    padding: 20px;
  }

  &:hover {
    @media(min-width: $lg) {
      @include Shadow-1;
    }
  }

  p {
    margin: 0;
  }

  &__meta {
    @include zero;
    display: grid;
    padding-right: 30px;
    gap: 10px;

    @media(min-width: $lg) {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  &__category {
    @include zero;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      min-width: 24px;
      width: 24px;
      height: 24px;
      margin-bottom: auto;
    }

    span {
      @include Text-14-reg;
      color: $Main-1;
    }
  }

  &__label {
    @include zero;
    @include Text-14-reg;
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 8px;
    color: $Main-1;
    background-color: $t-40;
    justify-self: start;

    @media(min-width: $xxl) {
      @include Text-14-bold;
      padding-top: 5px;
      padding-right: 15px;
      padding-bottom: 5px;
      padding-left: 15px;
    }

    &--green {
      background-color: $Alternative-4;
      color: $Main-2;
    }

    &--brown {
      background-color: $Alternative-10;
      color: $Main-1;
    }

    &--gray {
      background-color: transparent;
      color: $t-60;
      border: 1px solid $t-60;
    }
  }

  &__date {
    @include zero;
    @include Text-14-reg;
    color: $t-60;
  }

  &__more {
    @include zero;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    @media(min-width: $xxl) {
      top: 24px;
      right: 20px;
    }

    &:hover {
      @media(min-width: $lg) {

        svg {
          fill: $Corporate-2;
        }
      }
    }

    svg {
      transition: 0.3s;
      fill: $Main-1;
    }
  }

  &__header {
    @include zero;
    @include Text-16-bold;
    color: $Main-1;
    text-decoration: none;
    transition: color 0.3s;

    @media(min-width: $xxl) {
      @include Text-18-bold;
    }

    &[href]:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }
  }

  &__description {
    @include Text-14-reg;

    @media(min-width: $xxl) {
      @include Text-16-reg;
    }
  }

  &__list {
    @include zero;
    display: grid;
    list-style: none;
    row-gap: 5px;

    li {
      @include zero;
      @include Text-14-reg;
      position: relative;
      padding-left: 16px;

      &:before {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $Main-1;
        content: '';
        top: 6px;
        left: 0;

        @media(min-width: $lg) {
          top: 7px;
        }
      }
    }
  }

  &__footer {
    @include zero;
    display: grid;
    width: 100%;
    margin-top: auto;
    justify-content: start;
    gap: 10px;

    @media(min-width: $lg) {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  &__type {
    @include zero;
    display: flex;
    column-gap: 5px;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      @include Text-12-reg;
      color: $Main-1;
    }
  }

  &__subscription {
    @include zero;
    display: flex;
    column-gap: 5px;
    align-items: center;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 8px;
    border: 1px solid $Main-1;
    justify-self: flex-start;

    @media(min-width: $xxl) {
      padding-right: 15px;
      padding-left: 15px;
    }

    svg {
      min-width: 14px;
      width: 14px;
      height: 14px;
    }

    p {
      @include zero;
      @include Text-12-reg;
    }

    &--orange {
      color: $Alternative-1;
      border-color: $Alternative-1;
    }

    &--green {
      color: $Alternative-4;
      border-color: $Alternative-4;
    }
  }


  &__views {
    @include zero;
    @include Text-12-reg;
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-self: start;

    @media(min-width: $lg) {
      margin-left: auto !important;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__hashtags {
    @include zero;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5px;
    row-gap: 5px;
  }

  &__hashtag {
    @include zero;
    @include Text-14-reg;
    color: $Main-6;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Main-1;
      }
    }
  }

  &__video {
    @include zero;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 10px;

    @media(min-width: 425px) {
      height: 250px;
    }
    @media(min-width: $md) {
      border-radius: 20px;
    }
    @media(min-width: $xl) {
      height: 540px;
    }
  }

  &__gray {
    @include zero;
    @include Text-14-reg;
    color: $t-60;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}